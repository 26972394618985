import React, { useState, useCallback, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
	useGetAttachmentDownloadMutation,
	useGetByIdQuery,
	useGetUpdateDistributionSettingsForRecipientsMutation,
} from "api/Api";
import { enqueueSnackbar } from "notistack";
import LastSegmentOfUrl from "lib/helpers/LastSegmentOfUrl";
import colors from "styles/colors-theme";

import {
	styled,
	Box,
	Chip,
	Divider,
	Button,
	Stack,
	Tooltip,
	CircularProgress,
	Checkbox,
	OutlinedInput,
	IconButton,
	Menu,
	MenuItem,
	Typography,
} from "@mui/material";
import { DataGrid, GridColDef, GridSortModel } from "@mui/x-data-grid";
import {
	ILatestDispatchSent,
	ISalaryReport,
	ISalaryReportError,
	ISalaryReportRecipient,
	IUpdateDistributionSettingsForRecipients,
} from "types/dispatch/dispatchListTable/DispatchListTableTypes";

import AlertMessage from "components/common/alert/AlertMessage";
import DispatchPreviewButton from "./components/DispatchPreviewButton";
import RowSettingsButton from "components/dispatch/DispatchListTable/components/RowSettingsButton";
import { ListItemCard } from "components/common/list/ListItemCard";
import LoadingButton from "components/common/loading/LoadingButton";
import ModalDialog from "components/common/modal/ModalDialog";
import StatusChip from "components/common/chip/StatusChip";

import SendDispatchListsModalContent from "./components/sendDispatch/SendDispatchListsModalContent";
import FilterButton from "./components/FilterButton";

import { ReactComponent as DispatchlistIcon } from "assets/icons/dispatchlist.svg";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import SearchIcon from "@mui/icons-material/Search";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { ReactComponent as UsersIcon } from "assets/icons/user-group-regular.svg";
import { ReactComponent as SendListIcon } from "assets/icons/send-dispatch-list.svg";
import { ReactComponent as CommentRegularIcon } from "assets/icons/comment-regular.svg";
import { ReactComponent as UserIcon } from "assets/icons/user-group-21.svg";

import theme from "styles/theme";
import SalaryReportCommentsModalContent from "./components/SalaryReportCommentsModalContent";
import EditFortroendevaldModal from "./editAgentModal/EditFortroendevaldModal";
import { IAgent, IResponsible } from "types/dispatch/dispatchListTable/SelectAgentModal";

const IconBox = styled(Box)(() => ({
	color: "#3699FF",
	display: "flex",
}));

const StyledBox = styled(Box)({
	display: "flex",
	flexDirection: "column",
}) as typeof Box;

const StyledBoxSpace = styled(Box)({
	display: "flex",
	flexDirection: "column",
	paddingTop: 12,
	" > *": {
		marginBottom: 12,
		minHeight: 20,
	},
}) as typeof Box;

const DataGridStyled = styled(DataGrid)({
	// since hiding first header that is row with error
	"& .MuiDataGrid-columnHeaders": {
		border: 0,
		paddingLeft: 4,
	},
	// hide header for error on row column
	"& .MuiDataGrid-columnHeader": {
		"&:first-of-type": {
			display: "none",
		},
	},
	"& .MuiDataGrid-row": {
		"& .MuiDataGrid-cell": {
			minHeight: "79px !important",
		},
		"&:nth-of-type(2n)": { backgroundColor: colors.background.body },
	},
	"& .MuiDataGrid-cell": {
		"&:first-of-type": {
			padding: "0px !important",
		},
	},
}) as typeof DataGrid;

interface IDistributionRecipientOptions {
	recipientId: string;
	activateEmail: boolean;
	activateAddress: boolean;
}

export interface IFilterProps {
	notStarted: number;
	started: number;
	investigation: number;
	sent: number;
}

export interface ITemplatesTableToolbar {
	rowFilters: IFilterProps;
	setRowFilters: React.Dispatch<React.SetStateAction<IFilterProps>>;
}

const showDispatchListTable = () => {
	const navigate = useNavigate();
	const { state } = useLocation();
	const { dispatchListIdFromState } = state || "";
	const dispatchListIdFromUrl = LastSegmentOfUrl(location.href);
	const dispatchListId: string = dispatchListIdFromState
		? dispatchListIdFromState
		: dispatchListIdFromUrl;

	const {
		data: data,
		isLoading: isLoadingData,
		isFetching: isFetchingData,
		refetch: refetchList,
		isError,
	} = useGetByIdQuery(dispatchListId);

	const [rows, setRows] = useState<ISalaryReport[]>([]);
	const [filteredRows, setFilteredRows] = useState<ISalaryReport[]>([]);
	const [filterValue, setFilterValue] = useState(sessionStorage.getItem("filterValue") ?? "");
	const [rowFilters, setRowFilters] = useState<IFilterProps>({
		notStarted: sessionStorage.getItem("rowFilters.notStarted")
			? Number(sessionStorage.getItem("rowFilters.notStarted"))
			: 1,
		started: sessionStorage.getItem("rowFilters.started")
			? Number(sessionStorage.getItem("rowFilters.started"))
			: 1,
		investigation: sessionStorage.getItem("rowFilters.investigation")
			? Number(sessionStorage.getItem("rowFilters.investigation"))
			: 1,
		sent: sessionStorage.getItem("rowFilters.sent")
			? Number(sessionStorage.getItem("rowFilters.sent"))
			: 1,
	});

	useEffect(() => {
		if (data && data.salaryReports && data.salaryReports.length > 0) setRows(data.salaryReports);
	}, [data.salaryReports]);

	const [sortModel, setSortModel] = useState<GridSortModel>([
		{
			field: "workplace",
			sort: "asc",
		},
	]);

	const [editDistribution, setEditDistribution] = useState<string>("");

	const [getAttachmentDownload] = useGetAttachmentDownloadMutation();

	const [
		getUpdateDistributionSettingsForRecipients,
		{ isLoading: isLoadingUpdatedDistributionSettings },
	] = useGetUpdateDistributionSettingsForRecipientsMutation();

	const [recipients, setRecipients] = useState<IUpdateDistributionSettingsForRecipients>({
		salaryReportId: "",
		recipients: [{ recipientId: "", activateAddress: false, activateEmail: false }],
	});

	const getDispatchColor = (dispatchType: ILatestDispatchSent) => {
		const type = dispatchType
			? dispatchType.status == 500
				? 500
				: dispatchType.type
				? dispatchType.type
				: 0
			: null;

		switch (type) {
			case 10:
				return colors.primary;
			case 20:
				return colors.warning2;
			case 30:
				return colors.purple.main;
			case 500:
				return colors.error2;
			default:
				return colors.grey;
		}
	};

	const handleEditDistribution = useCallback(
		(id: string) => {
			if (data) {
				const salaryReports = data.salaryReports;
				const rowIndex = salaryReports.map((row: any) => row.id).indexOf(id);

				const dataRecipients = salaryReports[rowIndex].representativeRecipients;
				const dataWorkPlace = salaryReports[rowIndex].workplace;
				const tempList: IDistributionRecipientOptions[] = [];
				tempList.push({
					recipientId: dataWorkPlace.recipientId,
					activateEmail: dataWorkPlace.emailActivated,
					activateAddress: dataWorkPlace.addressActivated,
				});

				dataRecipients.forEach((r: ISalaryReportRecipient) => {
					tempList.push({
						recipientId: r.recipientId ?? "",
						activateEmail: r.emailActivated,
						activateAddress: r.addressActivated,
					});
				});

				const form = {
					salaryReportId: id,
					recipients: tempList,
				};

				setRecipients(form);
			}
			setEditDistribution(id);
		},
		[data],
	);

	const handleCheckboxChange = async (e: React.ChangeEvent<HTMLInputElement>, id: string) => {
		const checkedName = e.target.name;
		const tempList = [...recipients.recipients];
		if (tempList.length > 0) {
			tempList.find((r, i) => {
				if (r.recipientId === id) {
					if (checkedName === "activateEmail") {
						tempList[i] = {
							recipientId: tempList[i].recipientId,
							activateEmail: !tempList[i].activateEmail,
							activateAddress: tempList[i].activateAddress,
						};
					} else {
						tempList[i] = {
							recipientId: tempList[i].recipientId,
							activateEmail: tempList[i].activateEmail,
							activateAddress: !tempList[i].activateAddress,
						};
					}
					return true; // stop searching
				}
			});
			setRecipients({ salaryReportId: recipients.salaryReportId, recipients: tempList });
		}
	};

	const saveDistributionSettings = async () => {
		await getUpdateDistributionSettingsForRecipients(recipients)
			.unwrap()
			.then((response) => {
				enqueueSnackbar("Distributionssätt uppdaterade", { variant: "success" });
			})
			.catch((error) => {
				enqueueSnackbar(error.data.detail, {
					variant: "error",
					persist: true,
				});
			});
		refetchList();
		setEditDistribution("");
	};

	useEffect(() => {
		const tempRows: ISalaryReport[] = [];

		if (data.salaryReports) {
			if (
				rowFilters.notStarted &&
				rowFilters.started &&
				rowFilters.investigation &&
				rowFilters.sent
			) {
				tempRows.push(...data.salaryReports);
			} else {
				data.salaryReports.filter((row: ISalaryReport) => {
					if (rowFilters.started && row.status == 200) tempRows.push(row);
					if (rowFilters.notStarted && row.status == 100) tempRows.push(row);
					if (rowFilters.investigation && row.status == 250) tempRows.push(row);
					if (rowFilters.sent && row.status == 300) tempRows.push(row);
				});
			}
			setRows(tempRows);
		} else {
			setRows(data.salaryRepors);
		}
	}, [rowFilters, data.salaryReports]);

	useEffect(() => {
		if (filterValue != "") {
			const tempList: any[] = [];
			rows.forEach((row: ISalaryReport) => {
				if (
					(row.statusMessage != null &&
						row.statusMessage.toLowerCase().includes(filterValue.toLowerCase())) ||
					(row.workplace.name &&
						row.workplace.name.toLowerCase().includes(filterValue.toLowerCase())) ||
					(row.workplace.municipality &&
						row.workplace.municipality.toLowerCase().includes(filterValue.toLowerCase())) ||
					(row.workplace.address.fullAddress &&
						row.workplace.address.fullAddress.toLowerCase().includes(filterValue.toLowerCase())) ||
					(row.workplace.departmentNumber &&
						row.workplace.departmentNumber.toString().includes(filterValue.toLowerCase())) ||
					(row.workplace.organizationNumber &&
						row.workplace.organizationNumber.toString().includes(filterValue.toLowerCase()))
				) {
					tempList.push(row);
				}
			});

			rows.forEach((row: ISalaryReport) => {
				row.representativeRecipients.forEach((r: any) => {
					if (r.name.toLowerCase().includes(filterValue.toLowerCase())) {
						// add or replace by row id
						const i = tempList.findIndex((_element: { id: string }) => _element.id === row.id);
						if (i > -1) tempList[i] = row;
						else tempList.push(row);
					}
				});
			});
			rows.forEach((row: ISalaryReport) => {
				row.errors.forEach((r: any) => {
					if (r.errorMessage.toLowerCase().includes(filterValue.toLowerCase())) {
						// add or replace by row id
						const i = tempList.findIndex((_element: { id: string }) => _element.id === row.id);
						if (i > -1) tempList[i] = row;
						else tempList.push(row);
					}
				});
			});
			rows.forEach((row: ISalaryReport) => {
				row.responsibles.forEach((r: IResponsible) => {
					if (r.name.toLowerCase().includes(filterValue.toLowerCase())) {
						const i = tempList.findIndex((_element: { id: string }) => _element.id === row.id);
						if (i > -1) tempList[i] = row;
						else tempList.push(row);
					}
				});
			});

			setFilteredRows(tempList);
		} else {
			setFilteredRows(rows);
		}
	}, [filterValue, data, rows]);

	const columns: GridColDef[] = [
		{
			field: "tableRowError",
			headerName: "",
			sortable: false,
			minWidth: 4,
			maxWidth: 4,
			renderCell: (params) => {
				const rowHasError = params.row.errors.length > 0 ? true : false;
				return (
					<Box
						sx={{
							background: `${rowHasError ? colors.error2 : "transparent"}`,
							width: "100%",
							height: "100%",
						}}
					/>
				);
			},
		},
		{
			field: "workplace",
			headerName: "Arbetsställe",
			sortable: true,
			align: "left",
			headerAlign: "left",
			flex: 2.5,
			minWidth: 250,
			renderCell: (row) => {
				return (
					<StyledBox>
						<>
							<Tooltip
								title={
									<>
										{row.value.organizationNumber && (
											<p style={{ padding: "0", margin: "4px 0", fontSize: "13px" }}>
												<span style={{ fontWeight: "500" }}>Organisationsnummer: </span>
												{row.value.organizationNumber}
											</p>
										)}
										{row.value.emails[0] && (
											<p style={{ padding: "0", margin: "4px 0" }}>
												<span style={{ fontWeight: "500" }}>E-post: </span>
												{row.value.emails[0] ?? ""}
											</p>
										)}
									</>
								}
								placement='bottom-start'
								arrow
							>
								<Stack>
									<span style={{ wordBreak: "break-word" }}>{row.value.name ?? ""}</span>
									<span
										style={{
											fontSize: "13px",
											fontWeight: 600,
											color: theme.palette.info.light,
											paddingTop: "4px",
										}}
									>
										{row.value.municipality ?? ""} - {row.value.number ?? -1}
									</span>
								</Stack>
							</Tooltip>
						</>
					</StyledBox>
				);
			},
			// todo isabelle control when more rows with different names
			sortComparator: (v1, v2) => v1.name.toLowerCase().localeCompare(v2.name.toLowerCase()),
		},
		{
			field: "address",
			headerName: "Adress",
			sortable: true,
			align: "left",
			headerAlign: "left",
			flex: 3,
			minWidth: 150,
			renderCell: (row) => {
				const address = row.row.workplace.address;
				return (
					<StyledBox>
						<span>{address?.street ?? ""}</span>
						<span>{(address?.postalCode ?? "") + " " + (address?.city ?? "")}</span>
					</StyledBox>
				);
			},
			sortComparator: (v1, v2) => v1.localeCompare(v2),
			valueGetter: (row) => row.row.workplace?.address?.fullAddress ?? "",
		},
		{
			field: "email",
			headerName: "E-post",
			sortable: false,
			align: "center",
			headerAlign: "center",
			flex: 1,
			minWidth: 80,
			renderCell: (row) => {
				const latestEmailDispatch = row.row.workplace.latestEmailDispatch ?? "";
				if (editDistribution != row.row.id) {
					return (
						<StyledBox>
							{row.row.workplace.emailActivated && (
								<Tooltip
									title={
										latestEmailDispatch ? (
											<>
												<p style={{ margin: 0 }}>
													{latestEmailDispatch.typeName ?? ""}{" "}
													{latestEmailDispatch.statusMessage.toLowerCase()}
												</p>
												{latestEmailDispatch.failedReason && (
													<i style={{ margin: 0 }}>{latestEmailDispatch.failedReason ?? ""}</i>
												)}
											</>
										) : (
											<p style={{ margin: 0 }}>Ej skickad</p>
										)
									}
									placement='bottom-start'
									arrow
								>
									<IconBox>
										<CheckCircleIcon
											sx={{
												fill: getDispatchColor(latestEmailDispatch),
											}}
											fontSize='small'
										/>
									</IconBox>
								</Tooltip>
							)}
						</StyledBox>
					);
				} else {
					return (
						<StyledBox>
							<Checkbox
								name={"activateEmail"}
								checked={recipients.recipients[0].activateEmail}
								onChange={(e) => handleCheckboxChange(e, recipients.recipients[0].recipientId)}
								disabled={!row.row.workplace.hasEmail}
							/>
						</StyledBox>
					);
				}
			},
		},
		{
			field: "letter",
			headerName: "Brev",
			sortable: false,
			align: "center",
			headerAlign: "center",
			flex: 1,
			minWidth: 80,
			renderCell: (row) => {
				const latestLetterDispatch = row.row.workplace.latestLetterDispatch ?? "";

				if (editDistribution != row.row.id) {
					return (
						<StyledBox>
							{row.row.workplace.addressActivated && (
								<Tooltip
									title={
										latestLetterDispatch ? (
											<>
												<p style={{ margin: 0 }}>
													{latestLetterDispatch.typeName ?? ""}{" "}
													{latestLetterDispatch.statusMessage.toLowerCase()}
												</p>
												{latestLetterDispatch.failedReason && (
													<i style={{ margin: 0 }}>{latestLetterDispatch.failedReason ?? ""}</i>
												)}
											</>
										) : (
											<p style={{ margin: 0 }}>Ej skickad</p>
										)
									}
									placement='bottom-start'
									arrow
								>
									<IconBox>
										<CheckCircleIcon
											sx={{ fill: getDispatchColor(latestLetterDispatch) }}
											fontSize='small'
										/>
									</IconBox>
								</Tooltip>
							)}
						</StyledBox>
					);
				} else {
					return (
						<StyledBox>
							<Checkbox
								name={"activateAddress"}
								checked={recipients.recipients[0].activateAddress}
								onChange={(e) => handleCheckboxChange(e, recipients.recipients[0].recipientId)}
								disabled={!row.row.workplace.hasAddress}
							/>
						</StyledBox>
					);
				}
			},
		},
		{
			field: "departmentNumber",
			headerName: "Avd.",
			sortable: false,
			align: "right",
			headerAlign: "right",
			flex: 1,
			minWidth: 60,
			renderCell: (row) => {
				return <StyledBoxSpace>{row.row.workplace.departmentNumber ?? ""}</StyledBoxSpace>;
			},
		},
		{
			field: "border1",
			headerName: "",
			sortable: false,
			align: "center",
			headerAlign: "center",
			width: 25,
			renderCell: () => <Divider orientation='vertical' flexItem />,
		},
		{
			field: "fortroendevald",
			headerName: "Förtroendevald",
			sortable: false,
			align: "left",
			headerAlign: "left",
			flex: 3,
			minWidth: 200,
			renderCell: (row) => {
				const [openAgentModal, setOpenAgentModal] = useState(false);
				const recipients = row.row.representativeRecipients ?? "";
				if (recipients.length > 0) {
					return (
						<StyledBoxSpace>
							{recipients.map((person: ISalaryReportRecipient, index: number) => {
								return (
									<Tooltip
										key={index}
										title={person?.emails?.map((e: string, idx: number) => (
											<p style={{ margin: 0 }} key={idx}>
												{e ?? ""}
											</p>
										))}
										placement='bottom-start'
										arrow
									>
										<span>{person.name ?? ""}</span>
									</Tooltip>
								);
							})}
						</StyledBoxSpace>
					);
				} else {
					return (
						<>
							<ModalDialog
								isVisible={openAgentModal}
								label={"Redigera förtroendevald"}
								icon={<UsersIcon />}
								content={
									<EditFortroendevaldModal
										agentName={row.row.workplace.name ?? ""}
										workplaceId={row.row.workplace.id}
										closeAgentModal={() => setOpenAgentModal(false)}
										salaryReportId={row.row.id}
									/>
								}
								showModal={() => setOpenAgentModal(false)}
							/>
							<StyledBox sx={{ my: "auto", height: "auto" }}>
								<Button
									variant='secondaryTable'
									onClick={() => setOpenAgentModal(true)}
									disabled={data.isArchived}
								>
									Välj
								</Button>
							</StyledBox>
						</>
					);
				}
			},
		},
		{
			field: "fEmail",
			headerName: "E-post",
			sortable: false,
			align: "center",
			headerAlign: "center",
			flex: 1,
			minWidth: 80,
			renderCell: (row) => {
				const rowRecipients = row.row.representativeRecipients ?? "";

				if (editDistribution != row.row.id) {
					return (
						<StyledBoxSpace>
							{rowRecipients.map((fEmail: ISalaryReportRecipient, index: number) => {
								return (
									<span key={index}>
										{fEmail.emailActivated && (
											<Tooltip
												title={
													fEmail.latestEmailDispatch ? (
														<>
															<p style={{ margin: 0 }}>
																{fEmail.latestEmailDispatch.typeName ?? ""}{" "}
																{fEmail.latestEmailDispatch.statusMessage.toLowerCase()}
															</p>
															{fEmail.latestEmailDispatch.failedReason && (
																<i style={{ margin: 0 }}>
																	{fEmail.latestEmailDispatch.failedReason}
																</i>
															)}
														</>
													) : (
														<p style={{ margin: 0 }}>Ej skickad</p>
													)
												}
												placement='bottom-start'
												arrow
											>
												<IconBox>
													<CheckCircleIcon
														sx={{ fill: getDispatchColor(fEmail.latestEmailDispatch) }}
														fontSize='small'
													/>
												</IconBox>
											</Tooltip>
										)}
									</span>
								);
							})}
						</StyledBoxSpace>
					);
				} else {
					return (
						<StyledBoxSpace>
							{rowRecipients.map((recipient: ISalaryReportRecipient, index: number) => {
								const checked = recipients.recipients.find(
									(r) => r.recipientId == recipient.recipientId,
								)?.activateEmail;
								return (
									<span key={index}>
										<Checkbox
											name={"activateEmail"}
											checked={checked}
											onChange={(e) => handleCheckboxChange(e, recipient.recipientId ?? "")}
											disabled={!recipient.hasEmail}
										/>
									</span>
								);
							})}
						</StyledBoxSpace>
					);
				}
			},
		},
		{
			field: "fLetter",
			headerName: "Brev",
			sortable: false,
			align: "center",
			headerAlign: "center",
			flex: 1,
			minWidth: 80,
			renderCell: (row) => {
				const rowRecipients = row.row.representativeRecipients ?? "";
				if (editDistribution != row.row.id) {
					return (
						<StyledBoxSpace>
							{rowRecipients.map((fLetter: ISalaryReportRecipient, index: number) => {
								return (
									<span key={index}>
										{fLetter.addressActivated && (
											<Tooltip
												title={
													fLetter.latestLetterDispatch ? (
														<>
															<p style={{ margin: 0 }}>
																{fLetter.latestLetterDispatch.typeName ?? ""}{" "}
																{fLetter.latestLetterDispatch.statusMessage.toLowerCase()}
															</p>
															{fLetter.latestLetterDispatch.failedReason && (
																<i style={{ margin: 0 }}>
																	{fLetter.latestLetterDispatch.failedReason}
																</i>
															)}
														</>
													) : (
														<p style={{ margin: 0 }}>Ej skickad</p>
													)
												}
												placement='bottom-start'
												arrow
											>
												<IconBox>
													<CheckCircleIcon
														sx={{ fill: getDispatchColor(fLetter.latestLetterDispatch) }}
														fontSize='small'
													/>
												</IconBox>
											</Tooltip>
										)}
									</span>
								);
							})}
						</StyledBoxSpace>
					);
				} else {
					return (
						<StyledBoxSpace>
							{rowRecipients.map((recipient: ISalaryReportRecipient, index: number) => {
								const checked = recipients.recipients.find(
									(r) => r.recipientId == recipient.recipientId,
								)?.activateAddress;
								return (
									<span key={index}>
										<Checkbox
											name={"activateAddress"}
											checked={checked}
											onChange={(e) => handleCheckboxChange(e, recipient.recipientId ?? "")}
											disabled={!recipient.hasAddress}
										/>
									</span>
								);
							})}
						</StyledBoxSpace>
					);
				}
			},
		},
		{
			field: "border2",
			headerName: "",
			sortable: false,
			align: "center",
			headerAlign: "center",
			renderCell: () => <Divider orientation='vertical' flexItem />,
			width: 25,
		},
		{
			field: "responsibles",
			headerName: "Ombud",
			sortable: false,
			align: "center",
			headerAlign: "center",
			width: 75,
			renderCell: (row) => {
				const responsibles = row.row.responsibles;
				if (responsibles && responsibles.length > 0) {
					return (
						<Tooltip
							title={responsibles.map((r: IResponsible, idx: number) => {
								return (
									<p
										key={idx}
										style={{
											margin: "0",
										}}
									>
										{r.name}
									</p>
								);
							})}
							placement='bottom-start'
							arrow
						>
							<span>
								<IconButton disabled aria-label='filtrera' color={"primary"}>
									<UserIcon />
								</IconButton>
							</span>
						</Tooltip>
					);
				}
			},
		},
		{
			field: "border3",
			headerName: "",
			sortable: false,
			align: "center",
			headerAlign: "center",
			renderCell: () => <Divider orientation='vertical' flexItem />,
			width: 25,
		},
		{
			field: "Löneprotokoll status",
			headerName: "Löneprotokoll",
			sortable: false,
			align: "left",
			headerAlign: "left",
			flex: 3,
			minWidth: 150,
			renderCell: (params) => {
				const statusMessage = params.row.statusMessage;
				const status = params.row.status;
				return (
					<StyledBoxSpace>
						<StatusChip
							statusMessage={statusMessage && statusMessage.length > 0 ? statusMessage : ""}
							status={status ? status : 0}
						/>
					</StyledBoxSpace>
				);
			},
		},
		{
			field: "btns",
			headerName: " ",
			align: "right",
			sortable: false,

			flex: editDistribution ? 4 : 3,
			minWidth: editDistribution ? 200 : 150,

			renderCell: (row) => {
				const hasComments = row.row.hasComments;
				const hasAttachment = row.row.attachment != null && true;
				const salaryReportId = row.row.id;
				const [openSalaryReportComments, setOpenSalaryReportComments] = useState(false);

				const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
				const open = Boolean(anchorEl);
				const handleClick = (event: React.MouseEvent<HTMLElement>) => {
					setAnchorEl(event.currentTarget);
				};
				const handleClose = () => {
					setAnchorEl(null);
				};

				// rapport vara påbörjad/inskickad
				if (row.id != editDistribution && row.row.status >= 200) {
					return (
						<>
							{openSalaryReportComments && (
								<ModalDialog
									isVisible={openSalaryReportComments}
									label={"Kommentarer"}
									icon={
										<CommentRegularIcon
											color={theme.palette.primary.main}
											style={{ width: "20px", height: "20px" }}
										/>
									}
									content={
										<SalaryReportCommentsModalContent
											showModal={setOpenSalaryReportComments}
											salaryReport={row.row}
										/>
									}
									showModal={setOpenSalaryReportComments}
									minHeight='auto'
								/>
							)}

							<Box display={"flex"}>
								{hasAttachment && row.row.attachment.length > 0 && (
									<>
										<IconButton
											aria-label='bifogad fil'
											size='small'
											color='secondary'
											sx={{ mr: 1 }}
											onClick={handleClick}
										>
											<AttachFileIcon fontSize='medium' />
										</IconButton>
										<Menu anchorEl={anchorEl} open={open} onClose={handleClose}>
											<Typography
												sx={{
													fontWeight: 600,
													fontSize: 14,
													marginLeft: "20px",
													marginBottom: "8px",
												}}
											>
												Bilagor
											</Typography>
											{row.row.attachment.map((a: any) => {
												return (
													<MenuItem
														sx={{ fontWeight: 500, fontSize: 13, textOverflow: "ellipsis" }}
														key={a.id}
														onClick={() => {
															handleClose();
															getAttachmentDownload({
																salaryReportId: salaryReportId,
																attachmentId: a.id,
																name: a.name,
															});
														}}
													>
														<Typography noWrap sx={{ fontWeight: 500, fontSize: 13 }}>
															{a.name}
														</Typography>
													</MenuItem>
												);
											})}
										</Menu>
									</>
								)}
								{hasComments && (
									<IconButton
										onClick={() => setOpenSalaryReportComments(true)}
										aria-label='meddelande'
										size='small'
										color='secondary'
										sx={{ mr: 1 }}
									>
										<CommentRegularIcon
											color={
												hasComments ? theme.palette.primary.main : theme.palette.action.disabled
											}
											style={{ width: "20px", height: "20px" }}
										/>
									</IconButton>
								)}

								<Button
									aria-label='öppna'
									variant='primaryTable'
									onClick={(e) => {
										e.preventDefault();
										e.stopPropagation();
										navigate(
											`/utskickslistor/${
												data.isArchived ? "arkiverade" : "aktuella"
											}/${dispatchListId}/${row.row.id}`,
										);
									}}
								>
									Öppna
								</Button>
							</Box>
						</>
					);
				}
				if (row.id != editDistribution && row.row.status <= 200) {
					return (
						<StyledBoxSpace sx={{ height: "auto" }}>
							{row.row.errors.map((error: ISalaryReportError, idx: number) => {
								return (
									<Chip key={idx} label={error.errorMessage ?? ""} color={"error"} size='small' />
								);
							})}
						</StyledBoxSpace>
					);
					// redigera distr.sätt läge
				} else if (editDistribution == row.row.id) {
					return (
						<Stack flexDirection='row' justifyContent={""}>
							{isLoadingUpdatedDistributionSettings ? (
								<LoadingButton label={"Sparar"} />
							) : (
								<>
									<Button
										variant='secondaryTable'
										sx={{ marginRight: 1 }}
										onClick={() => setEditDistribution("")}
									>
										Avbryt
									</Button>
									<Button variant='primaryTable' onClick={saveDistributionSettings}>
										Spara
									</Button>
								</>
							)}
						</Stack>
					);
				}
			},
		},

		{
			field: "menu",
			headerName: "",
			sortable: false,
			align: "center",
			headerAlign: "center",
			width: editDistribution ? 0 : 50,
			minWidth: editDistribution ? 0 : 50,
			flex: editDistribution ? 0 : 1,

			renderCell: (row) => {
				if (!editDistribution) {
					return (
						<RowSettingsButton
							changeEditDistribution={handleEditDistribution}
							editDistribution={editDistribution}
							agentName={row.row.workplace.name}
							salaryReportId={row.row.id}
							workplaceId={row.row.workplace.id}
							isDisabled={data.isArchived}
							status={row.row.status}
							selectedResponsibles={row.row.responsibles}
						/>
					);
				}
			},
		},

		{
			field: "errors",
			valueGetter: (params) => {
				const val = params.value;
				if (val.length > 0) {
					return val.length;
				}
			},
		},
	];
	return (
		<>
			{data && data.errorCount > 0 && (
				<Box mb={3}>
					<AlertMessage
						alertText={`Det finns ${data.errorCount} ${
							data.errorCount > 1 ? "rader" : "rad"
						} som behöver åtgärder.`}
						type='error'
						actionLink={
							<span
								style={{ color: colors.buttons.primary, cursor: "pointer" }}
								onClick={() =>
									setSortModel([
										{
											field: "errors",
											sort: "desc",
										},
									])
								}
							>
								{" "}
								Visa överst i lista.
							</span>
						}
					/>
				</Box>
			)}
			<Box sx={{ border: "0", width: "100%" }}>
				<Stack
					direction={"row"}
					sx={{
						my: { xs: 2, sm: 0 },
						mb: { xs: 3, sm: 4 },
						alignItems: "end",
						justifyContent: "end",
					}}
				>
					<Box
						sx={{
							display: "flex",
							width: { xxs: "100%", sm: "300px" },
						}}
					>
						<OutlinedInput
							fullWidth
							placeholder='Filtrera'
							onChange={(event) => {
								{
									setFilterValue(event.target.value);
									sessionStorage.setItem("filterValue", event.target.value);
								}
							}}
							value={filterValue}
							startAdornment={<SearchIcon sx={{ marginLeft: "8px", color: "#B5B5C3" }} />}
						/>
						<FilterButton rowFilters={rowFilters} setRowFilters={setRowFilters} />
					</Box>
				</Stack>
				{isLoadingData || isFetchingData ? (
					<CircularProgress />
				) : filterValue != "" && filteredRows.length == 0 ? (
					<p style={{ marginTop: 5 }}>Inga listor</p>
				) : data.salaryReports && data.salaryReports.length > 0 ? (
					<>
						<DataGridStyled
							autoHeight
							getRowHeight={() => "auto"}
							rowHeight={79}
							sortModel={sortModel}
							onSortModelChange={(model: GridSortModel) => setSortModel(model)}
							rows={filteredRows.length > 0 ? filteredRows : rows}
							columns={columns}
							disableColumnMenu
							disableSelectionOnClick
							disableRowSelectionOnClick
							disableColumnSelector
							getRowClassName={(params: any) => {
								if (params.id == editDistribution) return "editRow";
								return "";
							}}
							columnHeaderHeight={40}
							{...data}
							initialState={{
								...data.initialState,
								pagination: { paginationModel: { pageSize: 10, page: 0 } },
								columns: {
									columnVisibilityModel: {
										errors: false,
									},
								},
							}}
							pageSizeOptions={[10, 25, 50]}
						/>
					</>
				) : isError ? (
					<p style={{ marginTop: 5 }}>Kunde inte hämta listor</p>
				) : (
					<p style={{ marginTop: 5 }}>Inga listor</p>
				)}
			</Box>
		</>
	);
};

const headerMenu = () => {
	const { state } = useLocation();
	const { dispatchListIdFromState } = state || "";
	const dispatchListIdFromUrl = LastSegmentOfUrl(location.href);
	const dispatchListId: string = dispatchListIdFromState
		? dispatchListIdFromState
		: dispatchListIdFromUrl;
	const { data } = useGetByIdQuery(dispatchListId);
	const [showSendDispatchModal, setShowSendDispatchModal] = useState(false);

	return (
		<>
			<ModalDialog
				isVisible={showSendDispatchModal}
				label={"Skicka till utskickslista"}
				icon={<SendListIcon />}
				content={<SendDispatchListsModalContent showModal={setShowSendDispatchModal} />}
				showModal={setShowSendDispatchModal}
				minHeight='auto'
			/>
			<Stack spacing={1} direction={"row"}>
				{data ? (
					<DispatchPreviewButton
						id={data.agreementTypeId}
						dispatchListId={dispatchListId}
						disabled={data.isArchived}
					/>
				) : null}
				<Divider orientation='vertical' sx={{ display: { xxs: "none", xs: "flex" } }} />
				<Button
					sx={{
						width: "110px",
					}}
					aria-label='Skicka'
					variant='containedPrimary'
					startIcon={<MailOutlineIcon />}
					onClick={() => setShowSendDispatchModal(true)}
					disabled={data.isArchived}
				>
					Skicka
				</Button>
			</Stack>
		</>
	);
};

export default function DispatchListTable() {
	return (
		<ListItemCard
			icon={<DispatchlistIcon />}
			header={"Utskickslista"}
			content={showDispatchListTable()}
			headerAction={headerMenu()}
		/>
	);
}
